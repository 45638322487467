<template>
    <div class="wrapper">
        <div class="cols">
            <div class="col" v-for="(item,index) in list" :key="index" @click="selectedItem(item.scenicSpotId)">
                <div class="container">
                    <div class="front" :style="'background-image:url('+item.logoUrl+')'">
                        <div class="inner">
                            <span> {{ item.title }}</span>
                        </div>
                    </div>
                    <div class="back">
                        <div class="inner">
                            <p>{{ item.summary }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        list: {
            type: Array,
            default: []
        }
    },
    data () {
        return {

        }
    },
    mounted () {
    },
    methods: {
        selectedItem (id) {
            this.$router.push({
                path: '/ScenicDetails',
                query: {
                    id: id
                }
            });
        },

    }
}
</script>
<style lang="less" scoped>
.wrapper {
    width: 100%;
    margin: 0 auto;
    // max-width: 40rem;
    .nextPage {
        text-align: right;
    }
    .cols {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        .col {
            width: calc(33% - 2rem);
            margin: 0.5rem;
            cursor: pointer;
            .container {
                -webkit-transform-style: preserve-3d;
                transform-style: preserve-3d;
                -webkit-perspective: 1000px;
                perspective: 1000px;
                .front,
                .back {
                    background-size: cover;
                    background-position: center;
                    -webkit-transition: -webkit-transform 0.7s
                        cubic-bezier(0.4, 0.2, 0.2, 1);
                    transition: -webkit-transform 0.7s
                        cubic-bezier(0.4, 0.2, 0.2, 1);
                    -o-transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
                    transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
                    transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1),
                        -webkit-transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
                    -webkit-backface-visibility: hidden;
                    backface-visibility: hidden;
                    text-align: center;
                    min-height: 28vh;
                    height: auto;
                    border-radius: 10px;
                    color: #fff;
                    font-size: 1.5rem;
                }

                .front {
                    -webkit-transform: rotateY(0deg);
                    transform: rotateY(0deg);
                    -webkit-transform-style: preserve-3d;
                    transform-style: preserve-3d;
                    background-repeat: no-repeat;
                    background-size: 100% 100%;
                    background-color: #000;
                    .inner p {
                        font-size: 2rem;
                        margin-bottom: 2rem;
                        position: relative;
                        span {
                            color: rgba(255, 255, 255, 0.7);
                            font-family: 'Montserrat';
                            font-weight: 300;
                        }
                    }
                    .inner p:after {
                        content: '';
                        width: 4rem;
                        height: 2px;
                        position: absolute;
                        background: #c6d4df;
                        display: block;
                        left: 0;
                        right: 0;
                        margin: 0 auto;
                        bottom: -0.75rem;
                    }
                }

                .front:after {
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 1;
                    width: 100%;
                    height: 100%;
                    content: '';
                    display: block;
                    opacity: 0.6;
                    background-color: #000;
                    -webkit-backface-visibility: hidden;
                    backface-visibility: hidden;
                    border-radius: 10px;
                }

                .back {
                    -webkit-transform: rotateY(180deg);
                    transform: rotateY(180deg);
                    -webkit-transform-style: preserve-3d;
                    transform-style: preserve-3d;
                    background: #cedce7;
                    background: -webkit-linear-gradient(
                        45deg,
                        #cedce7 0%,
                        #596a72 100%
                    );
                    background: -o-linear-gradient(
                        45deg,
                        #cedce7 0%,
                        #596a72 100%
                    );
                    background: linear-gradient(
                        45deg,
                        #cedce7 0%,
                        #596a72 100%
                    );
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    .inner p {
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        -webkit-line-clamp: 6; //行数
                    }
                }

                .inner {
                    -webkit-transform: translateY(-50%) translateZ(60px)
                        scale(0.94);
                    transform: translateY(-50%) translateZ(60px) scale(0.94);
                    top: 50%;
                    position: absolute;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    padding: 0.5rem;
                    font-size: 0.7em;
                    -webkit-box-sizing: border-box;
                    box-sizing: border-box;
                    outline: 1px solid transparent;
                    -webkit-perspective: inherit;
                    perspective: inherit;
                    z-index: 2;
                    overflow: hidden;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }

            .container:hover .front,
            .container:hover .back {
                -webkit-transition: -webkit-transform 0.7s
                    cubic-bezier(0.4, 0.2, 0.2, 1);
                transition: -webkit-transform 0.7s
                    cubic-bezier(0.4, 0.2, 0.2, 1);
                -o-transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
                transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
                transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1),
                    -webkit-transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
            }

            .container:hover .back {
                -webkit-transform: rotateY(0deg);
                transform: rotateY(0deg);
                -webkit-transform-style: preserve-3d;
                transform-style: preserve-3d;
            }

            .container:hover .front {
                -webkit-transform: rotateY(-180deg);
                transform: rotateY(-180deg);
                -webkit-transform-style: preserve-3d;
                transform-style: preserve-3d;
            }
        }
    }
}

@media screen and (max-width: 64rem) {
    .col {
        width: calc(33.333333% - 2rem);
    }
}

@media screen and (max-width: 48rem) {
    .col {
        width: calc(50% - 2rem);
    }
}

@media screen and (max-width: 32rem) {
    .col {
        width: 100%;
        margin: 0 0 2rem 0;
    }
}
</style>