<template>
    <div class="BigBox">
        <div class="Head">
            <div class="Home" @click="goHome"></div>
            <div class="Title">
                特色景点
            </div>
            <div class="User" @click="goUser"></div>
        </div>
        <!-- Banner图 -->
        <div id="Banner" class="Banner">
            <div class="swiper">
                <div class="swiper-wrapper">
                    <div class="swiper-slide" v-for="(item,index) in bannerList" :key="index">
                        <img :src="item.advertUrl" style="width:100%;height: 100%;">
                    </div>

                </div>
            </div>
        </div>
        <!-- 热推景点 -->
        <div class="ConBox">
            <div class="HotBox">
                <div class="HotBoxMBL"></div>
                <div class="Title">
                    <div>热推景点</div>
                    <div></div>
                </div>
                <div class="ListBox" id="ListBox">
                    <div class="ItemBoxOne" v-for="(item,index) in HotList" :key="index" @mousemove="YiRuReTui($event,index)" :ref="'ReTuiref'+index" @mouseout="YiChuReTui(index)">
                        <!-- 背景图 -->
                        <img class="img" :src="item.logoUrl">
                        <div class="XiangQing" @click="goDetail(item.scenicSpotId)">
                            <img style="width: 70%;height: 70%;" src="../../assets/SpecialScenic/XingXing.png" alt="">
                        </div>
                        <!-- 主图盒子 -->
                        <div class="ZhuTuBox">
                            <img :src="item.logoUrl" style="width: 90%;height: 90%;border-radius: 10px;" alt="">
                        </div>
                        <!-- 详情 -->
                        <div class="ConBox">
                            {{ item.propaganda }}
                        </div>
                        <div class="Name">{{ item.title }}</div>
                    </div>
                </div>
            </div>

            <!-- 特色景点 -->
            <div class="SpecialBox">
                <div class="HotBoxMBL"></div>
                <div class="Title">
                    <div>特色景点</div>
                    <div></div>
                </div>
                <div class="wrapper">
                    <div class="cols">
                        <div class="col" v-for="(item,index) in SpecialList" :key="index" @mousemove="YiRuReTuiTwo($event,index)" @mouseout="YiChuReTuiTwo(index)">
                            <div class="container">
                                <div class="front" :style="'background-image:url('+item.logoUrl+')'">
                                    <div class="inner">
                                        <span> {{ item.title }}</span>
                                    </div>
                                </div>
                                <div class="back" style="">
                                    <div class="inner" style="display: flex;flex-direction: column;align-items: center;">
                                        <div class="FZJJ">{{ item.propaganda }}</div>
                                        <div @click="selectedItem(item.scenicSpotId)" style="padding: 0.5vh 2vh;border-radius: 5px;background-color: rgb(58, 58, 207);font-size: 2vh;">进入游览</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>
</template>
<script>
import $ from 'jquery'
import TiShiBox from '../Public/TiShiBox.vue'
import TeSeJingDian from './TeSeJingDian.vue'

export default {
    components: { TiShiBox, TeSeJingDian },
    data () {
        return {
            HotList: [],
            HotListShow: [],
            HtotalPage: 0,
            Hpage: 1,
            HotAreaList: [],
            HotAreaListShow: [],
            HAtotalPage: 0,
            HAPage: 1,
            SpecialList: [],

            StotalPage: 0,
            SPage: 1,
            VideoShow: false,
            videoUrl: '',
            msg: '',
            bannerList: []
        }
    },
    mounted () {
        this.getList()
        // 页面监听推出卡牌
        window.addEventListener('scroll', this.handleScroll)
    },
    methods: {
        selectedItem (id) {
            this.$router.push({
                path: '/ScenicDetails',
                query: {
                    id: id
                }
            });
        },
        // 移入热推事件
        YiRuReTui (e, index) {
            console.log();
            // 角度值
            let degR = 20
            // 鼠标盒子的高宽
            let HZWidth = e.srcElement.offsetWidth
            let HZHeight = e.srcElement.offsetHeight
            // 鼠标的轴
            let SBX = e.offsetX
            let SBY = e.offsetY
            $('.ItemBoxOne')[index].style.transform
                =
                'rotateX(' + (degR - SBY / (HZHeight / (degR * 2))).toFixed(1) + "deg)" + 'rotateY('
                + (SBX / (HZWidth / (degR * 2)) - degR).toFixed(1) + "deg)"
            $('.HotBox').css('background-image', 'url(' + this.HotList[index].logoUrl + ')')

        },
        YiRuReTuiTwo (e, index) {
            $('.SpecialBox').css('background-image', 'url(' + this.SpecialList[index].logoUrl + ')')
        },
        YiChuReTui (index) {
            setTimeout(() => {
                $('.HotBox').css('background-image', '')
                $('.ItemBoxOne')[index].style.transform = ''
            }, 500)
        },
        YiChuReTuiTwo (index) {
            setTimeout(() => {
                $('.SpecialBox').css('background-image', '')
            }, 500)
        },

        // 滚动
        handleScroll () {
            let scrollTop = document.documentElement.scrollTop || document.body.scrollTop // 滚动条偏移量
            if (scrollTop > 100) {
                $('.ListBox').css('left', '0')
            } else {
                $('.ListBox').css('left', '-150%')
            }
            if (scrollTop < 700) {
                setTimeout(() => {
                    $('.SpecialBox').css('background-image', '')
                }, 500)
            } else {
            }
        },
        // 特色景点请求数据
        getList () {
            let t = this
            var axios = require('axios');
            var config = {
                method: 'get',
                url: this.$Schttp + '/vtp/app/scenicSpot/featureScenicIndex',
            };
            axios(config)
                .then(function (response) {
                    let res = response.data
                    t.HotList = res.data.hotRecomms.recommItems
                    t.SpecialList = res.data.features
                    t.bannerList = res.data.banners
                    t.initSwiper()
                })
                .catch(function (error) {
                });
        },

        initSwiper () {
            let mySwiper = new Swiper('.swiper', {
                // mousewheel: true,
                // direction: 'vertical',
                speed: 1000,
                loop: true,
                autoplay: {
                    delay: 3000,
                },

            })
        },

        // 去首页
        goHome () {
            this.$router.push('/')
        },
        // 去我的or登录
        goUser () {
            if (this.$cookies.get("token") == null) {
                this.$router.push('/login')
            } else {
                this.$router.push('/personalCenter')
            }
        },
        // 去详情
        goDetail (id) {
            this.$router.push({
                path: '/ScenicDetails',
                query: {
                    id: id
                }
            });
        }

    },


}
</script>
<style lang="less" scoped>
.FZJJ {
    width: 100%;
    margin: 2vh 0;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 1.8vh;
    line-height: 3vh;
}
.BigBox {
    width: 100%;
    min-height: 100vh;

    z-index: -1;
    .Head {
        z-index: 1;
        height: 12vh;
        box-sizing: border-box;
        width: 100%;
        padding: 3vh 10% 0;
        box-sizing: border-box;
        // margin: 0 auto;

        display: flex;
        justify-content: space-between;
        padding-top: 3vh;
        background: url('../../assets/SpecialScenic/new/HeadBG.jpg') no-repeat;
        background-size: 100% 100%;
        .Home {
            background: url('../../assets/CulturalHomePage/PomePage.png')
                no-repeat;
            background-size: 100% 100%;
            height: 7vh;
            width: 7.5vh;
            cursor: pointer;
        }
        .Title {
            font-size: 4vh;
            background-size: 100% 100%;
            height: 3.25rem;
            width: 30.875rem;
            text-align: center;
            font-weight: 600;
            // color: #deecff;
        }
        .User {
            background: url('../../assets/CulturalHomePage/personal.png')
                no-repeat;
            background-size: 100% 100%;
            height: 7vh;
            width: 7.5vh;
            cursor: pointer;
        }
    }
    .Banner {
        width: 100%;
        height: 70vh;
        .swiper {
            width: 100%;
            height: 100%;
        }
        .swiper-wrapper {
            width: 100%;
            height: 100%;
            .swiper-slide {
                width: 100%;
                height: 100%;
                // position: relative;
            }
        }
    }
    .ConBox {
        background: url('../../assets/SpecialScenic/new/BG.jpg') no-repeat;
        background-size: 100% 100%;
    }
    .HotBox,
    .SpecialBox {
        width: 100%;
        padding: 0 12%;
        box-sizing: border-box;
        background-size: 100% 100%;
        position: relative;
        .HotBoxMBL {
            width: 100%;
            height: 100%;
            // background-color: rgba(0, 0, 0, 0.5);
            position: absolute;
            top: 0;
            left: 0;
            backdrop-filter: blur(10px);
        }
        .Title {
            position: relative;
            text-align: center;

            height: 6rem;
            width: 100%;
            z-index: 2;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            font-size: 3.5vh;
            div:nth-child(2) {
                width: 100%;
                height: 2vh;
                background-size: 100% 100%;
                background: url('../../assets/SpecialScenic/new/titleBg.png')
                    no-repeat center;
            }
        }
        .ListBox {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            width: 100%;
            position: relative;
            left: -150%;
            transform-style: preserve-3d;
            transition: 3s;
            .ItemBoxOne {
                width: 20%;
                height: 45vh;
                margin: 7vh 0;
                position: relative;
                transform-style: preserve-3d;
                transform: rotateX(var(--rx)) rotateY(var(--ry));
                transition: all 0.08s;
                color: #fff;
                z-index: 99;
                // overflow: hidden;
                .img {
                    width: 100%;
                    height: 100%;
                    border-radius: 20px;
                    overflow: hidden;
                    z-index: -1;
                }
                .MaoBoLi {
                    width: 100%;
                    height: 100%;
                    background-color: rgba(0, 0, 0, 0.5);
                    position: fixed;
                    border-radius: 20px;
                    top: 0;
                    left: 0;
                    backdrop-filter: blur(10px);
                }
                .XiangQing {
                    position: absolute;
                    top: 5vh;
                    left: -3vh;
                    width: 5vh;
                    height: 5vh;
                    border-radius: 10px;
                    background-color: rgba(255, 255, 255, 0.7);
                    border: 1px solid white;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    transform: translateZ(80px);
                }
                .ZhuTuBox {
                    position: absolute;
                    top: -2vh;
                    right: 3vh;
                    width: 15vh;
                    height: 15vh;
                    border-radius: 10px;
                    background-color: rgba(255, 255, 255, 0.5);
                    border: 1px solid white;
                    // backdrop-filter: blur(10px);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    transform: translateZ(80px);
                }
                .ConBox {
                    position: absolute;
                    bottom: 2vh;
                    right: -4vh;
                    width: 24vh;
                    height: 19vh;
                    padding: 1vh;
                    border-radius: 10px;
                    background-color: rgba(255, 255, 255, 0.7);
                    color: #000;
                    border: 1px solid white;
                    font-size: 1.5vh;
                    line-height: 2.8vh;
                    display: -webkit-box;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    -webkit-line-clamp: 7; /* 显示3行文本 */
                    transform: translateZ(80px);
                }
                .Name {
                    position: absolute;
                    top: 15vh;
                    right: 3vh;
                    color: #fff;
                    -webkit-text-stroke: 1px #3c3c3c;
                    font-weight: bolder;
                    font-size: 2.8vh;
                    transform: translateZ(60px);
                }
                .VideoBtn:hover:after {
                    content: '点击播放视频';
                    position: absolute;
                    bottom: -2rem;
                    color: #fff;
                    // background: rgba(0, 0, 0, 0.5);
                    white-space: normal;
                    width: 100px;
                    line-height: 2rem;
                    padding: 0 5px;
                    border-radius: 5px;
                    z-index: 99;
                }
                .VRBtn:hover:after {
                    content: '点击体验VR';
                    position: absolute;
                    bottom: -2rem;
                    color: #fff;
                    // background: rgba(0, 0, 0, 0.5);
                    white-space: normal;
                    width: 100px;
                    line-height: 2rem;
                    padding: 0 5px;
                    border-radius: 5px;
                    z-index: 99;
                }
            }
            // .ItemBox:hover:after {
            //     content: ' ';
            //     position: absolute;
            //     top: 0;
            //     bottom: 0;
            //     left: 0;
            //     right: 0;
            //     background: rgba(0, 0, 0, 0.3);
            // }
        }
    }

    .SpecialBox {
        padding-bottom: 2rem;
        // background: url('../../assets/SpecialScenic/new/bg2.jpg') no-repeat;
        background-size: 100% 100%;
        .Title {
            // background: url('../../assets/SpecialScenic/new/TSJD.jpg') no-repeat;
            // background-size: 100% 100%;
        }
        div .ListBox:nth-child(2) .ItemBox {
            // width: 35rem;
            width: 49.5%;
            height: 17.5rem;
        }
    }

    .More {
        position: absolute;
        text-align: center;
        z-index: 999;
        span {
            display: inline-block;
            padding: 0.3rem 1rem;
            background: #1a1b34;
            border-radius: 0.3rem;
            color: #fff;
            border: 2px solid #1034a5;
            cursor: pointer;
        }
    }

    .VideoBox {
        position: fixed;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        z-index: 100;
        background: rgba(0, 0, 0, 0.5);
        backdrop-filter: blur(10px);
        video {
            width: 60%;
            margin: 5rem 20%;
        }
    }
}
.wrapper {
    width: 100%;
    margin: 0 auto;
    // max-width: 40rem;
    .nextPage {
        text-align: right;
    }
    .cols {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        .col {
            width: calc(33% - 2rem);
            margin: 0.5rem;
            cursor: pointer;

            .container {
                -webkit-transform-style: preserve-3d;
                transform-style: preserve-3d;
                -webkit-perspective: 1000px;
                perspective: 1000px;
                .front,
                .back {
                    background-size: cover;
                    background-position: center;
                    -webkit-transition: -webkit-transform 0.7s
                        cubic-bezier(0.4, 0.2, 0.2, 1);
                    transition: -webkit-transform 0.7s
                        cubic-bezier(0.4, 0.2, 0.2, 1);
                    -o-transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
                    transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
                    transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1),
                        -webkit-transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
                    -webkit-backface-visibility: hidden;
                    backface-visibility: hidden;
                    text-align: center;
                    min-height: 28vh;
                    height: auto;
                    border-radius: 10px;
                    color: #fff;
                    font-size: 1.5rem;
                }

                .front {
                    -webkit-transform: rotateY(0deg);
                    transform: rotateY(0deg);
                    -webkit-transform-style: preserve-3d;
                    transform-style: preserve-3d;
                    background-repeat: no-repeat;
                    background-size: 100% 100%;
                    background-color: #000;
                    .inner p {
                        font-size: 2rem;
                        margin-bottom: 2rem;
                        position: relative;

                        span {
                            color: rgba(255, 255, 255, 0.7);
                            font-family: 'Montserrat';
                            font-weight: 300;
                        }
                    }

                    .inner p:after {
                        content: '';
                        width: 4rem;
                        height: 2px;
                        position: absolute;
                        background: #c6d4df;
                        display: block;
                        left: 0;
                        right: 0;
                        margin: 0 auto;
                        bottom: -0.75rem;
                    }
                }

                .front:after {
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 1;
                    width: 100%;
                    height: 100%;
                    content: '';
                    display: block;
                    opacity: 0.6;
                    background-color: #000;
                    -webkit-backface-visibility: hidden;
                    backface-visibility: hidden;
                    border-radius: 10px;
                }

                .back {
                    -webkit-transform: rotateY(180deg);
                    transform: rotateY(180deg);
                    -webkit-transform-style: preserve-3d;
                    transform-style: preserve-3d;
                    background: #cedce7;
                    background: -webkit-linear-gradient(
                        45deg,
                        #cedce7 0%,
                        #596a72 100%
                    );
                    background: -o-linear-gradient(
                        45deg,
                        #cedce7 0%,
                        #596a72 100%
                    );
                    background: linear-gradient(
                        45deg,
                        #cedce7 0%,
                        #596a72 100%
                    );
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    .inner p {
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        -webkit-line-clamp: 6; //行数
                    }
                }

                .inner {
                    -webkit-transform: translateY(-50%) translateZ(60px)
                        scale(0.94);
                    transform: translateY(-50%) translateZ(60px) scale(0.94);
                    top: 50%;
                    position: absolute;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    padding: 0.5rem;
                    font-size: 0.7em;
                    -webkit-box-sizing: border-box;
                    box-sizing: border-box;
                    outline: 1px solid transparent;
                    -webkit-perspective: inherit;
                    perspective: inherit;
                    z-index: 2;
                    overflow: hidden;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }

            .container:hover .front,
            .container:hover .back {
                -webkit-transition: -webkit-transform 0.7s
                    cubic-bezier(0.4, 0.2, 0.2, 1);
                transition: -webkit-transform 0.7s
                    cubic-bezier(0.4, 0.2, 0.2, 1);
                -o-transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
                transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
                transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1),
                    -webkit-transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
            }

            .container:hover .back {
                -webkit-transform: rotateY(0deg);
                transform: rotateY(0deg);
                -webkit-transform-style: preserve-3d;
                transform-style: preserve-3d;
            }

            .container:hover .front {
                -webkit-transform: rotateY(-180deg);
                transform: rotateY(-180deg);
                -webkit-transform-style: preserve-3d;
                transform-style: preserve-3d;
            }
        }
    }
}

@media screen and (max-width: 64rem) {
    .col {
        width: calc(33.333333% - 2rem);
    }
}

@media screen and (max-width: 48rem) {
    .col {
        width: calc(50% - 2rem);
    }
}

@media screen and (max-width: 32rem) {
    .col {
        width: 100%;
        margin: 0 0 2rem 0;
    }
}
</style>